import server from './server'
import { getToken } from '@/utils/token'
import qs from 'qs'

export const getInfo = () => {
  const token = getToken()
  return server.requestPost(
    'stores/tdd/stox_user/info',
    qs.stringify({
      token,
    })
  )
}
export const getInfoQuery = (token) => {
  return server.requestPost(
    'stores/tdd/stox_user/info',
    qs.stringify({
      token,
    })
  )
}

export const getMessages = (curpage, page_size, unread = 0) => {
  const token = getToken()
  return server.requestGet('stores/tdd/stox_member/messages', {
    token,
    curpage,
    page_size,
    unread,
  })
}

/**
 * 个人中心推荐投资项目列表
 * @returns
 */
export const getRecommend = () => {
  const token = getToken()
  return server.requestGet('/stores/tdd/stox_project/all_list', {
    token,
  })
}

/**
 * 个人中心推荐投资项目列表
 * @returns
 */
export const getProject = () => {
  const token = getToken()
  return server.requestGet('/stores/tdd/stox_project_member/order_list', {
    token,
  })
}

export const getDocuments = () => {
  const token = getToken()
  return server.requestGet('/stores/tdd/stox_member/document_list', {
    token,
  })
}

export const getCards = () => {
  const token = getToken()
  return server.requestGet('/stores/tdd/stox_member/bank_card_list', {
    token,
  })
}

export const getConfigData = () => {
  const token = getToken()
  return server.requestPostJson('/stores/tdd/config_data/info', {
    token,
  })
}

/**
 * 添加银行卡
 * @param {*} bank_id
 * @param {*} bank_name
 * @param {*} bank_swift_code
 * @param {*} bank_region
 * @param {*} bank_card_number
 * @param {*} bank_cardholder_name
 * @param {*} file
 * @returns
 */
export const addBandCard = (data) => {
  const token = getToken()
  return server.requestPost(
    `/stores/tdd/stox_member/add_bank_card?token=${token}`,
    data
  )
}

export const editBandCard = (data) => {
  const token = getToken()
  return server.requestPost(
    `/stores/tdd/stox_member/update_bank_card?token=${token}`,
    data
  )
}

export const delBandCard = (bank_guid) => {
  const token = getToken()
  return server.requestPost(
    `/stores/tdd/stox_member/delete_bank_card?token=${token}`,
    qs.stringify({
      bank_guid,
    })
  )
}

export const getBandCardInfo = (bank_guid) => {
  const token = getToken()
  return server.requestGet('/stores/tdd/stox_member/bank_card_info?', {
    token,
    bank_guid,
  })
}

export const getMessageInfo = (message_guid) => {
  const token = getToken()
  return server.requestGet('/stores/tdd/stox_member/message_info', {
    token,
    message_guid,
  })
}

export const readMessage = (type, message_guid) => {
  const token = getToken()
  return server.requestPost(
    `/stores/tdd/stox_member/message_read?token=${token}`,
    qs.stringify({
      type,
      message_guid,
    })
  )
}

export const sendCode = (country_code, mobile) => {
  const token = getToken()
  return server.requestPost(
    `/stores/tdd/stox_member/send_change_sms?token=${token}`,
    qs.stringify({
      country_code,
      mobile,
    })
  )
}

/**
 * 修改手机
 * @param {*} country_code
 * @param {*} mobile
 * @param {*} code
 * @param {*} password
 * @returns
 */
export const changeMobile = (country_code, mobile, code, password) => {
  const token = getToken()
  return server.requestPost(
    `/stores/tdd/stox_member/change_mobile?token=${token}`,
    qs.stringify({
      country_code,
      mobile,
      code,
      password,
    })
  )
}

/**
 * 修改密码
 * @param {*} old_password
 * @param {*} new_password
 * @param {*} password_confirm
 * @returns
 */
export const changePassword = (
  old_password,
  new_password,
  password_confirm
) => {
  const token = getToken()
  return server.requestPost(
    `/stores/tdd/stox_member/change_passwd?token=${token}`,
    qs.stringify({
      old_password,
      new_password,
      password_confirm,
    })
  )
}

/**修改钱包地址 */
export const changeWallet = (wallet_address) => {
  const token = getToken()
  return server.requestPost(
    `/stores/tdd/stox_member/update_user_info?token=${token}`,
    qs.stringify({
      wallet_address,
    })
  )
}

/**修改entity name */
export const changeEntity = (entity_name) => {
  const token = getToken()
  return server.requestPost(
    `/stores/tdd/stox_member/update_user_info?token=${token}`,
    qs.stringify({
      entity_name,
    })
  )
}

/**修改 name */
export const changeName = (first_name, last_name) => {
  const token = getToken()
  return server.requestPost(
    `/stores/tdd/stox_member/update_user_info?token=${token}`,
    qs.stringify({
      first_name,
      last_name,
    })
  )
}

/**
 * 项目的订单统计百分比数据
 * @param {*} guid
 * @returns
 */
export const getProjectOrderAmount = (guid) => {
  const token = getToken()
  return server.requestGet('/stores/tdd/stox_project/order_amount?', {
    token,
    guid,
  })
}

/**
 * 项目详情页面-去投资查询余额代金券税费比例
 * @param {*} guid
 * @returns
 */
export const getQueryBalanceVoucher = () => {
  const token = getToken()
  return server.requestPost(
    '/stores/tdd/stox_project_member/query_balance_voucher',
    qs.stringify({
      token,
    })
  )
}

/**
 * 项目详情页面-投资数据提交保存
 * @returns
 */
export const pushProject = (project_guid, total_purchase, pw) => {
  const token = getToken()
  return server.requestPost(
    `/stores/tdd/stox_project_member/purchase_save?token=${token}`,
    qs.stringify({
      project_guid,
      total_purchase,
      pw,
    })
  )
}

export const getJyMinxi = (curpage, page, currency, category) => {
  const token = getToken()
  return server.requestPost(
    '/stores/tdd/stox_user/transaction_record',
    qs.stringify({
      token,
      curpage,
      page,
      currency,
      category,
    })
  )
}

export const getinvested = (order_guid) => {
  const token = getToken()
  return server.requestPost(
    `stores/tdd/stox_member/get_order_detail`,
    qs.stringify({
      token,
      order_guid,
    })
  )
}
export const getAiyunkycUrl = (merchantUserId, metaInfo, returnUrl) => {
  return server.requestPost(
    `stores/tdd/aliyunkyc/get_facelive_url`,
    qs.stringify({
      merchantUserId,
      metaInfo,
      returnUrl,
    })
  )
}

export const getFaceResult = (merchantBizId, transactionId) => {
  return server.requestPost(
    `stores/tdd/aliyunkyc/get_face_check_result`,
    qs.stringify({
      merchantBizId,
      transactionId,
    })
  )
}

export const getAllWallets = () => {
  const token = getToken()

  return server.requestPost(
    `stores/tdd/sgtox_chainup_wallet/all`,
    qs.stringify({
      token,
    })
  )
}

export const delAllWallet = (guid) => {
  const token = getToken()
  return server.requestPost(
    `stores/tdd/sgtox_chainup_wallet/remove_wallet`,
    qs.stringify({
      token,
      guid,
    })
  )
}
export const addWallet = (wallet_address, wallet_name, provider) => {
  const token = getToken()
  return server.requestPost(
    `stores/tdd/sgtox_chainup_wallet/add_wallet`,
    qs.stringify({
      token,
      wallet_address,
      wallet_name,
      provider,
    })
  )
}

export const getTransferList = (categ) => {
  const token = getToken()
  return server.requestPost(
    `stores/tdd/dapp_dmz/transfer_list`,
    qs.stringify({
      token,
      categ,
    })
  )
}

export const saveForm = (form) => {
  const token = getToken()
  return server.requestPost(
    `stores/tdd/stox_home/contact_us_save`,
    qs.stringify({
      token,
      ...form,
    })
  )
}

export const getFundTransferList = (categ) => {
  const token = getToken()
  return server.requestPost(
    `stores/tdd/dapp_dmz_fund/transfer_list`,
    qs.stringify({
      token,
      categ,
      curpage: 1,
      page: 1000,
    })
  )
}

export const getFundTransferListById = (user_guid, status, categ) => {
  const token = getToken()
  return server.requestPost(
    `stores/tdd/dapp_dmz_fund/user_transfer_list`,
    qs.stringify({
      token,
      user_guid,
      categ,
      status,
      curpage: 1,
      page: 1000,
    })
  )
}

export const transferUpdate = (categ, detail, contract_address) => {
  return server.requestPost(
    `stores/tdd/dapp_dmz_blockchain/transfer_update`,
    qs.stringify({
      categ,
      detail,
      contract_address,
    })
  )
}

export const vaulttransferUpdate = (guid, categ, detail) => {
  const token = getToken()
  return server.requestPost(
    `stores/tdd/dapp_dmz/transfer_update`,
    qs.stringify({
      token,
      guid,
      categ,
      detail,
    })
  )
}

export const burnApprovedUpdate = (guid) => {
  const token = getToken()
  return server.requestPost(
    `stores/tdd/dapp_dmz_fund/burn_approved_update`,
    qs.stringify({
      guid,
      token,
    })
  )
}
export const getFundTransferListPre = (categ, status) => {
  const token = getToken()
  return server.requestPost(
    `stores/tdd/dapp_dmz_fund/transfer_list`,
    qs.stringify({
      token,
      categ,
      status,
      curpage: 1,
      page: 1000,
    })
  )
}

export const transferUpdateDemo = (categ, detail, contract_address) => {
  return server.requestPost(
    `stores/tdd/dapp_dmz_blockchain/transfer_update`,
    qs.stringify({
      categ,
      detail,
      contract_address,
    })
  )
}

export const transferSave = (
  categ,
  amount,
  lender_address,
  contract_address,
  coin_symbol = 'DST'
) => {
  const token = getToken()
  return server.requestPost(
    `stores/tdd/dapp_dmz/transfer_save`,
    qs.stringify({
      token,
      categ,
      amount,
      lender_address,
      contract_address,
      coin_symbol,
    })
  )
}

export const transferBlock = (
  categ,
  amount,
  lender_address,
  contract_address,
  coin_symbol,
  detail
) => {
  const token = getToken()
  return server.requestPost(
    `stores/tdd/dapp_dmz/save_block_detail`,
    qs.stringify({
      token,
      categ,
      amount,
      lender_address,
      contract_address,
      coin_symbol,
      detail,
    })
  )
}

export const getexchangeList = () => {
  const token = getToken()
  return server.requestPost(
    `stores/tdd/stox_member/exchange_list`,
    qs.stringify({
      token,
      page: 100,
      curpage: 1,
    })
  )
}

export const getvaultList = () => {
  const token = getToken()
  return server.requestPost(
    `stores/tdd/dmz_fund/investor_vault_list`,
    qs.stringify({
      token,
      page: 100,
      curpage: 1,
    })
  )
}
export const getDst = (user_guid) => {
  const token = getToken()
  return server.requestPost(
    `stores/tdd/dapp_dmz_fund/user_wallet_amount`,
    qs.stringify({
      token,
      user_guid
    })
  )
}
export const addUserInvestment = (user_guid, investment_amount, fund_amount, fund_subscription_time, memo) => {
  const token = getToken()
  return server.requestPost(
    `stores/tdd/dmz_fund/add_user_investment`,
    qs.stringify({
      token,
      user_guid,
      investment_amount,
      fund_amount,
      fund_subscription_time,
      memo
    })
  )
}

