import server from './server'

export const getAboutList = (module, curpage, page) => {
  return server.requestMutiGet('stores/tdd/stox_cms/articles', {
    module,
    curpage,
    page,
  })
}

export const getAboutDetail = (guid) => {
  return server.requestMutiGet('stores/tdd/stox_cms/article', {
    guid,
  })
}
