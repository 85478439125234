import Vue from 'vue'
import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import { Loading } from 'element-ui'
import router from '../router/index'
import { clearAll } from '@/utils/token'
const host = location.origin.indexOf('192.168') > 0 ? '/' : location.origin
import store from '@/store'

// const host = 'http://adminstox.tandidi.com'  //本地

const config = {
  // 基础url前缀
  baseURL: `${host}`,
  // 请求头信息
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    Language: 'en',
    client_type: 'PC',
  },

  headersJson: {
    'Content-Type': 'application/json; charset=UTF-8',
    Language: 'en',
    client_type: 'PC',
  },

  headersForm: {
    'Content-Type': 'multipart/form-data',
    'Encode-Type': 'multipart/form-data',
    Language: 'en',
    client_type: 'PC',
  },

  // 参数
  data: {},
  // 设置超时时间
  timeout: 0,
  // 携带凭证
  withCredentials: false,
  // 返回数据类型
  responseType: 'json',
}
// var proxy_tdd = ''
// console.log(process.env.NODE_ENV, '99999')

// if (process.env.NODE_ENV == 'production') {
//   proxy_tdd = '/chain/tddchain/'
// } else {
//   proxy_tdd = '/stores/tddchain/'
// }
// 添加请求拦截器

// const loading = this.$loading({
//   lock: true,
//   text: 'Loading',
//   spinner: 'el-icon-loading',
//   background: 'rgba(0, 0, 0, 0.7)',
// })
// setTimeout(() => {
//   loading.close()
// }, 2000)
const request = (options) => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: config.baseURL,
      headers: config.headers,
      timeout: config.timeout,
    })
    // 添加请求拦截器
    instance.interceptors.request.use(
      (config) => {
        if (
          config.method.toLocaleLowerCase() === 'post' ||
          config.method.toLocaleLowerCase() === 'put'
        ) {
          // config.data = JSON.stringify(config.data)
        }
        // const loading = this.$loading({
        //   lock: true,
        //   text: 'Loading',
        //   spinner: 'el-icon-loading',
        //   background: 'rgba(0, 0, 0, 0.7)',
        // })
        // setTimeout(() => {
        //   loading.close()
        // }, 2000)
        return config
      },
      (error) => {
        return reject(error)
      }
    )
    // 添加响应拦截器

    instance.interceptors.response.use(
      (response) => {
        if (response.status === 200) {
          // 请求成功
          if (response.data.status === 5) {
            let url = window.location.origin + 'index.html'
            window.location.replace(url)
          }
        }
        if (response.data.code == -401 || response.data.code == -402) {
          store.commit('SET_TOKEN', '')
          clearAll()
          router.push({ name: 'login' })
        }
        return response
      },
      (err) => {
        if (err.response.status === 504 || err.response.status === 404) {
          Message.error({
            duration: 0,
            message: '服务器被吃了⊙﹏⊙∥',
            showClose: true,
          })
        } else if (err.response.status === 401) {
          clearAll()
          Message.error({
            duration: 0,
            message: '登录信息失效⊙﹏⊙∥',
            showClose: true,
          })
        } else if (err.response.status === 500) {
          Message.error({
            duration: 0,
            message: '服务器开小差了⊙﹏⊙∥',
            showClose: true,
          })
        }
        return reject(err)
      }
    )

    instance(options)
      .then((res) => {
        // if (res.data.code == -401) {
        //   this.$router.push('login')
        // }

        resolve(res.data)

        return false
      })
      .catch((err) => {
        reject(err)
      })
  })
}

const requestJson = (options) => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: config.baseURL,
      headers: config.headersJson,
      timeout: config.timeout,
    })
    // 添加请求拦截器
    instance.interceptors.request.use(
      (config) => {
        if (
          config.method.toLocaleLowerCase() === 'post' ||
          config.method.toLocaleLowerCase() === 'put'
        ) {
        }

        return config
      },
      (error) => {
        return reject(error)
      }
    )
    // 添加响应拦截器

    instance.interceptors.response.use(
      (response) => {
        if (response.status === 200) {
          // 请求成功
          if (response.data.status === 5) {
            let url = window.location.origin + 'index.html'
            window.location.replace(url)
          }
        }
        if (response.data.code == -401 || response.data.code == -402) {
          store.commit('SET_TOKEN', '')
          clearAll()
          router.push({ name: 'login' })
        }
        return response
      },
      (err) => {
        if (err.response.status === 504 || err.response.status === 404) {
          Message.error({
            duration: 0,
            message: '服务器被吃了⊙﹏⊙∥',
            showClose: true,
          })
        } else if (err.response.status === 401) {
          Message.error({
            duration: 0,
            message: '登录信息失效⊙﹏⊙∥',
            showClose: true,
          })
        } else if (err.response.status === 500) {
          Message.error({
            duration: 0,
            message: '服务器开小差了⊙﹏⊙∥',
            showClose: true,
          })
        }
        return reject(err)
      }
    )

    instance(options)
      .then((res) => {
        resolve(res.data)

        return false
      })
      .catch((err) => {
        reject(err)
      })
  })
}

const requestMutipart = (options) => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: config.baseURL,
      headers: config.headersForm,
      timeout: config.timeout,
    })
    // 添加请求拦截器
    instance.interceptors.request.use(
      (config) => {
        if (
          config.method.toLocaleLowerCase() === 'post' ||
          config.method.toLocaleLowerCase() === 'put'
        ) {
        }

        return config
      },
      (error) => {
        return reject(error)
      }
    )
    // 添加响应拦截器

    instance.interceptors.response.use(
      (response) => {
        if (response.status === 200) {
          // 请求成功
          if (response.data.status === 5) {
            let url = window.location.origin + 'index.html'
            window.location.replace(url)
          }
        }
        if (response.data.code == -401 || response.data.code == -402) {
          store.commit('SET_TOKEN', '')
          clearAll()
          router.push({ name: 'login' })
        }
        return response
      },
      (err) => {
        if (err.response.status === 504 || err.response.status === 404) {
          Message.error({
            duration: 0,
            message: '服务器被吃了⊙﹏⊙∥',
            showClose: true,
          })
        } else if (err.response.status === 401) {
          Message.error({
            duration: 0,
            message: '登录信息失效⊙﹏⊙∥',
            showClose: true,
          })
        } else if (err.response.status === 500) {
          Message.error({
            duration: 0,
            message: '服务器开小差了⊙﹏⊙∥',
            showClose: true,
          })
        }
        return reject(err)
      }
    )

    instance(options)
      .then((res) => {
        resolve(res.data)

        return false
      })
      .catch((err) => {
        reject(err)
      })
  })
}

const requestPost = (url, data) => {
  return request({
    url: `${url}`,
    method: 'post',
    data,
  })
}
const requestGet = (url, params) => {
  return request({
    url: `${url}`,
    method: 'get',
    params: {
      ...params,
      token: sessionStorage.getItem('token'),
    },
  })
}

const requestPostJson = (url, data) => {
  return requestJson({
    url: `${url}`,
    method: 'post',
    data,
  })
}

const requestMuti = (url, data) => {
  return requestMutipart({
    url: `${url}`,
    method: 'post',
    data,
  })
}
const requestMutiGet = (url, params) => {
  return requestMutipart({
    url,
    method: 'get',
    params,
  })
}

export default {
  requestPost,
  requestGet,
  requestPostJson,
  requestMuti,
  requestMutiGet,
}
// Vue.prototype.requestPost = requestPost;
// Vue.prototype.requestGet = requestGet;
